export const REACT_APP_TESTNET_HASH: string = process.env.REACT_APP_TESTNET_HASH || 'SGO1GKSzyE7IEPItTxCByw9x8FmnrCDexi9/cOUJOiI=';
export const REACT_APP_MAINNET_HASH: string = process.env.REACT_APP_MAINNET_HASH || 'wGHE2Pwdvd7S12BL5FaOP20EGYesN73ktiC1qzkkit8=';
export const REACT_APP_BETANET_HASH: string = process.env.REACT_APP_MAINNET_HASH || 'mFgazF+2uRS1tMiL9dsj01hJGySEmPN28B/TjjvpVW0=';

export const REACT_APP_SANDNET_GENESIS_ID: string = process.env.REACT_APP_SANDNET_GENESIS_ID || 'sandnet-v1';
export const REACT_APP_BETA_CONSENSUS_VERSION: string = process.env.REACT_APP_BETA_CONSENSUS_VERSION;
export const REACT_APP_STABLE_CONSENSUS_VERSION: string = process.env.REACT_APP_STABLE_CONSENSUS_VERSION;
export const REACT_APP_MASTER_CONSENSUS_VERSION: string = process.env.REACT_APP_MASTER_CONSENSUS_VERSION;
export const REACT_APP_NIGHTLY_CONSENSUS_VERSION: string = process.env.REACT_APP_NIGHTLY_CONSENSUS_VERSION;

export const REACT_APP_NETWORK: string = process.env.REACT_APP_NETWORK || "";
